import React, { useState, useContext, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../api";

export const AuthContextUpdater = React.createContext(() => {});
export const AuthContext = React.createContext({ loading: true });

const initialRefreshToken = window.localStorage.getItem("rs_refreshToken");

export default function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken || "");
  const [loading, setLoading] = useState(!!initialRefreshToken);
  const navigate = useNavigate();

  const setTokens = (tokens) => {
    setAccessToken(tokens.access);
    setRefreshToken(tokens.refresh);
    navigate("/");
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    window.localStorage.setItem("rs_refreshToken", "");
    API.defaults.headers.common.Authorization = "";
    setUser(null);
    navigate("/login");
  };

  useEffect(() => {
    if (accessToken) {
      API.defaults.headers.common.Authorization = `bearer ${accessToken}`;
      const decoded = jwtDecode(accessToken);
      if (decoded?.account) {
        setUser(decoded);
        setLoading(false);
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (refreshToken) {
      window.localStorage.setItem("rs_refreshToken", refreshToken);
      const refreshAccessToken = async () => {
        let newAccessToken = "";
        await axios
          .post(
            `${process.env.REACT_APP_AUTH_URL}/refresh`,
            { token: refreshToken },
            { headers: { Authorization: `Bearer ${refreshToken}` } }
          )
          .then((result) => {
            if (result.data.access_token) {
              newAccessToken = result.data.access_token;
            } else {
              setLoading(false);
              setUser(undefined);
            }
            setAccessToken(newAccessToken);
            setTimeout(() => {
              setRefreshToken(result.data.refresh_token);
            }, 200);
            API.defaults.headers.common.Authorization = `bearer ${newAccessToken}`;
          })
          .catch(() => {
            setLoading(false);
            setUser(undefined);
          });
        return newAccessToken;
      };

      if (!accessToken) {
        refreshAccessToken();
      }

      const requestInterceptor = API.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            await refreshAccessToken();
            return API(originalRequest);
          }
          return Promise.reject(error);
        }
      );
      return () => {
        API.interceptors.request.eject(requestInterceptor);
      };
    }
  }, [refreshToken, accessToken]);

  return (
    <AuthContext.Provider value={{ user, accessToken, refreshToken, loading }}>
      <AuthContextUpdater.Provider value={{ setTokens, logout }}>
        {children}
      </AuthContextUpdater.Provider>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const { user, accessToken, refreshToken, loading } = useContext(AuthContext);
  const { setTokens, logout } = useContext(AuthContextUpdater);
  return { user, accessToken, refreshToken, setTokens, loading, logout };
}
