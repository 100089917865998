import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
import { toast } from "react-toastify";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/heatpump-bg.jpg";
import { useAuth } from "context/AuthProvider";
import MDAlert from "components/MDAlert";
import ClipLoader from "react-spinners/ClipLoader";
import jwtDecode from "jwt-decode";

function Login({ signOff }) {
  const { logout, setTokens } = useAuth();
  const [busy, setBusy] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [accessToken, setAccessToken] = useState();
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const [signedOff, setSignedOff] = useState(false);
  const [mfa, setMfa] = useState(false);

  useEffect(() => {
    if (signOff && logout && !signedOff) {
      setSignedOff(true);
      logout();
    }
  }, [signOff, logout, signedOff]);

  const loginHandler = (event) => {
    event.preventDefault();
    if (email.length > 0 && password.length > 0 && !busy) {
      setBusy(true);
      setFormError("");
      axios
        .post(`${process.env.REACT_APP_AUTH_URL}/login`, { username: email, password })
        .then((result) => {
          if (result.data.access_token) {
            const decoded = jwtDecode(result.data.access_token);

            if (decoded.typ === "mfa") {
              setAccessToken(result.data.access_token);
              setMfa(true);
              setBusy(false);
            } else {
              setTokens({ refresh: result.data.refresh_token, access: result.data.access_token });
            }
          } else {
            setBusy(false);
            toast.warning("Error, can't login! Please try again");
          }
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            toast.warning(error.response?.data?.message);
            setFormError(error.response?.data?.message);
          } else {
            toast.warning("Error, can't login! Please try again");
          }
          setBusy(false);
        });
    } else if (!email || !password) {
      toast.warning("Enter your email and password");
    }
  };

  const otpHandler = (event) => {
    event.preventDefault();
    if (otp.length > 0 && !busy) {
      setBusy(true);
      setFormError("");
      axios
        .post(
          `${process.env.REACT_APP_AUTH_URL}/login/otp`,
          {
            otp,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((result) => {
          if (result.data.access_token) {
            setTokens({ refresh: result.data.refresh_token, access: result.data.access_token });
          } else {
            setBusy(false);
            setMfa(false);
            toast.warning("Error, can't login! Please try again");
          }
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            toast.warning(error.response?.data?.message);
            setFormError(error.response?.data?.message);
          } else {
            toast.warning("Error, can't login! Please try again");
          }
          setBusy(false);
          setMfa(false);
        });
    } else if (!email || !password) {
      toast.warning("Enter your security code");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Mímir
          </MDTypography>
        </MDBox>
        {mfa ? (
          <MDBox pt={4} pb={3} px={3}>
            <MDAlert color="info">
              Please enter the security code we have send to your email address
            </MDAlert>
            <form className="MuiBox-root css-1ircn5c" onSubmit={otpHandler}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Security code"
                  fullWidth
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </MDBox>

              {formError && (
                <MDAlert color="primary" dismissible>
                  {formError}
                </MDAlert>
              )}
              <MDBox mt={4} mb={1}>
                {busy ? (
                  <ClipLoader loading size={50} />
                ) : (
                  <MDButton variant="gradient" color="info" fullWidth type="submit">
                    sign in
                  </MDButton>
                )}
              </MDBox>
            </form>
          </MDBox>
        ) : (
          <MDBox pt={4} pb={3} px={3}>
            <form className="MuiBox-root css-1ircn5c" onSubmit={loginHandler}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
              {formError && (
                <MDAlert color="primary" dismissible>
                  {formError}
                </MDAlert>
              )}
              <MDBox mt={4} mb={1}>
                {busy ? (
                  <ClipLoader loading size={50} />
                ) : (
                  <MDButton variant="gradient" color="info" fullWidth type="submit">
                    sign in
                  </MDButton>
                )}
              </MDBox>
            </form>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot your password?{" "}
                <MDTypography
                  component={Link}
                  to="/forgot-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Reset password
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
      </Card>
    </BasicLayout>
  );
}

export default Login;
