// @mui material components
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import AppMarkdown from "appDocumentation.md";

function NewPart() {
  const [markDown, setMarkDown] = useState("");
  console.log(markDown);
  useEffect(() => {
    fetch(AppMarkdown).then((response) => {
      setMarkDown(response.text());
      console.log(response);
    });
    fetch(AppMarkdown)
      .then((res) => res.text())
      .then((text) => setMarkDown(text));
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={2}>
            <h1>Documentation</h1>
            <br />
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markDown}</ReactMarkdown>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewPart;
