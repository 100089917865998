// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useGetSingleProject } from "hooks/useQueries";

// Data
import { useParams, useNavigate } from "react-router-dom";
import PartEdit from "components/PartEdit/PartEdit";
import Div100vh from "react-div-100vh";
import Loader from "react-spinners/ClipLoader";

function NewPart() {
  const params = useParams();
  const navigate = useNavigate();
  const { projectId } = params;
  const { data: selectedProject, isLoading } = useGetSingleProject({
    id: projectId,
    options: { enabled: !!projectId },
  });

  if (isLoading) {
    return (
      <Div100vh className="not-logged-in">
        <Loader />
      </Div100vh>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: 1 }}>
                <MDTypography variant="h5" fontWeight="medium">
                  {`Add new part${selectedProject ? ` to product: ${selectedProject.name}` : ""}`}
                </MDTypography>
              </div>
            </div>
          </MDBox>
          <Card sx={{ boxShadow: "none" }}>
            <PartEdit
              motherProjectId={projectId}
              noHeader
              finishHandler={() => navigate(`/app/dashboard`)}
            />
          </Card>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewPart;
