// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useQueryClient } from "react-query";

// Data
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import {
  useGetSinglePart,
  useUpdatePart,
  useDeletePart,
  useAddPart,
  useAddPartNewVersion,
  useAddPartNewRevision,
  useGetProjects,
  useGetCadSystems,
  useGetCategories,
  useGetParts,
} from "hooks/useQueries";
import Loader from "components/Loader/Loader";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { useAuth } from "context/AuthProvider";
import Select from "react-select";
import { WithContext as ReactTags } from "react-tag-input";
import "./PartEdit.scss";
import { API } from "api";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const getRunningNumber = (rn) => {
  if (!rn || rn === 0) {
    return "NEW";
  }
  return `000${rn}`.slice(-3);
};

function PartEdit({ partId, finishHandler, motherProjectId, noHeader }) {
  const { data: selectedPart, isLoading } = useGetSinglePart({
    id: partId,
    options: { enabled: !!partId },
  });
  const { mutateAsync: updatePart } = useUpdatePart({ id: partId });
  const { mutateAsync: deletePart } = useDeletePart({ id: partId });
  const { mutateAsync: addPart } = useAddPart();
  const { mutateAsync: addPartNewVersion } = useAddPartNewVersion({
    id: partId,
  });
  const { mutateAsync: addPartNewRevision } = useAddPartNewRevision({
    id: partId,
  });
  const { data: projects } = useGetProjects();
  const { data: categories } = useGetCategories();
  const { data: cadSystems } = useGetCadSystems();
  const { user } = useAuth();
  const { data: parts } = useGetParts();
  const [suggestions, setSuggestions] = useState([]);
  const [partNumber, setPartNumber] = useState("");
  useEffect(() => {
    if (parts) {
      setSuggestions(
        [...new Set(parts.flatMap((part) => part.tags))].map((tag) => ({
          id: tag,
          text: tag,
        }))
      );
    }
  }, [parts]);

  const queryCache = useQueryClient();
  const navigate = useNavigate();

  const isAdmin = user?.roles?.includes("admin");
  const isGuest = user?.roles?.includes("guest");

  const [valuesUpdated, setValueUpdated] = useState(false);
  const [name, setName] = useState("");
  const [svn, setSvn] = useState("");
  const [partIndex, setPartIndex] = useState("");
  const [drawingIndex, setDrawingIndex] = useState("");
  const [runningNumber, setRunningNumber] = useState(0);
  const [partNumberLoading, setPartNumberLoading] = useState(false);

  const [projectId, setProjectId] = useState(motherProjectId || "");
  const [cadSystemId, setCadSystemId] = useState("");
  const [cat1Id, setCat1Id] = useState("");
  const [cat2Id, setCat2Id] = useState("");
  const [cat3Id, setCat3Id] = useState("");

  const matchingProject = projects?.find((project) => project.id === projectId);
  const matchingCadSystem = cadSystems?.find((cadSystem) => cadSystem.id === cadSystemId);
  const matchingCat1 = categories?.find((cat) => cat.id === cat1Id);
  const matchingCat2 = categories?.find((cat) => cat.id === cat2Id);
  const matchingCat3 = categories?.find((cat) => cat.id === cat3Id);

  let reviewStatus = "draft";

  if (
    selectedPart?.reviewers &&
    selectedPart.reviewers.filter((review) => review.status === "approved").length >= 3
  ) {
    reviewStatus = "approved";
  } else if (selectedPart?.reviewers) {
    reviewStatus = selectedPart?.stage;
  }

  const matchingParts = parts
    ? parts.filter(
        (part) =>
          part.project_id === projectId &&
          part.category1_id === cat1Id &&
          part.category2_id === cat2Id &&
          part.category3_id === cat3Id
      )
    : [];

  const basedOnPart = matchingParts?.find((p) => p.running_number === runningNumber);

  useEffect(() => {
    const getNewPartNumber = async () => {
      try {
        setPartNumberLoading(true);
        const result = await API.get(`${process.env.REACT_APP_API_URL}/part-number`, {
          params: {
            project_id: projectId,
            ...(!!cat1Id && { category_1_id: cat1Id }),
            ...(!!cat2Id && { category_2_id: cat2Id }),
            ...(!!cat3Id && { category_3_id: cat3Id }),
            ...(runningNumber > 0 && { running_number: String(runningNumber) }),
            ...(!!partIndex && partIndex !== "ZZZ" && { part_index: partIndex }),
          },
        });
        setPartNumberLoading(false);
        if (result) {
          setPartNumber(result.data);
        }
      } catch (err) {
        setPartNumberLoading(false);
        if (err.response?.data?.message) {
          toast.warning(err.response?.data?.message);
        } else {
          toast.warning("Error retrieving part number");
        }
      }
    };
    if (projectId && cat1Id && cat2Id && cat3Id && !partId) {
      getNewPartNumber();
    }
  }, [projectId, cat1Id, cat2Id, cat3Id, runningNumber, partIndex, partId]);

  const [tags, setTags] = useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  useEffect(() => {
    if (basedOnPart) {
      setName(basedOnPart.name);
    }
  }, [basedOnPart]);

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  useEffect(() => {
    if (!valuesUpdated && selectedPart) {
      setValueUpdated(true);
      setName(selectedPart.name);
      setSvn(selectedPart.svn_revision_number);
      setPartIndex(selectedPart.part_index);
      setDrawingIndex(selectedPart.drawing_index);
      setRunningNumber(selectedPart.running_number);
      setProjectId(selectedPart.project_id);
      setCadSystemId(selectedPart.cad_system_id);
      setCat1Id(selectedPart.category1_id);
      setCat2Id(selectedPart.category2_id);
      setCat3Id(selectedPart.category3_id);
      setTags(selectedPart.tags?.map((tag) => ({ id: tag, text: tag })));
    }
  }, [valuesUpdated, selectedPart]);

  const saveHandler = async (e) => {
    e.preventDefault();
    try {
      if (partId) {
        await updatePart({
          ...selectedPart,
          name,
          svn_revision_number: svn,
          cad_system_id: cadSystemId,
          part_index: partIndex ? String(partIndex) : "",
          running_number: runningNumber ? String(runningNumber) : "NEW",
          category1_id: cat1Id,
          category2_id: cat2Id,
          category3_id: cat3Id,
          project_id: projectId,
          tags: tags.map((tag) => tag.text),
        });
        toast.success("Changes saved!");
        queryCache.invalidateQueries(`getSinglePart-${partId}`);
      } else {
        await addPart({
          name,
          user_id: user.sub,
          svn_revision_number: svn,
          cad_system_id: cadSystemId,
          drawing_index: drawingIndex,
          part_index: partIndex === "ZZZ" ? "" : String(partIndex),
          running_number: runningNumber ? String(runningNumber) : "NEW",
          category1_id: cat1Id,
          category2_id: cat2Id,
          category3_id: cat3Id,
          project_id: projectId,
          tags: tags.map((tag) => tag.text),
        });
        toast.success("Part saved!");
      }

      queryCache.invalidateQueries("getParts");
      finishHandler();
    } catch (err) {
      if (err.response?.data?.detail?.[0]?.msg) {
        toast.warning(
          `${err.response?.data?.detail?.[0]?.msg} -> ${err.response?.data?.detail?.[0]?.loc?.[1]}`
        );
      } else {
        toast.warning("Error, something went wrong");
      }
    }
  };

  const deleteHandler = () => {
    confirmAlert({
      title: "Are you sure?",
      message: "Really delete this part?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deletePart();
            toast.success("Part deleted!");
            queryCache.invalidateQueries("getParts");
            navigate("/");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const newVersionHandler = async () => {
    const part = await addPartNewVersion();
    navigate(`/app/dataset/${part.id}`);
  };

  const newRevisionHandler = async () => {
    const part = await addPartNewRevision();
    navigate(`/app/dataset/${part.id}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (partId && !selectedPart) {
    return (
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              part not found
            </MDTypography>
            <MDTypography variant="button" color="text">
              Cannot find part with id: {partId}
            </MDTypography>
          </MDBox>
        </Card>
      </MDBox>
    );
  }

  return (
    <>
      {!noHeader && (
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {partId ? `${selectedPart?.name}` : "New Part"}
          </MDTypography>
        </MDBox>
      )}

      <Card sx={{ boxShadow: "none" }}>
        <MDBox p={2}>
          <MDBox component="form" onSubmit={saveHandler}>
            <Grid item xs={3} sm={12}>
              <MDTypography variant="h5" color="text" style={{ marginLeft: 5 }}>
                {`Partnumber: ${selectedPart?.part_number || partNumber}`}
                {partNumberLoading && "..."}
              </MDTypography>
            </Grid>
            <br />
            <p style={{ fontSize: 12 }}>Product*</p>
            <Select
              placeholder="Select product"
              isDisabled={!!partId}
              className="rs__select"
              classNamePrefix="react-select"
              options={
                projects
                  ? projects.map((project) => ({
                      label: `${project.number} - ${project.name}`,
                      value: project.id,
                    }))
                  : []
              }
              value={
                matchingProject
                  ? {
                      value: matchingProject.id,
                      label: `${matchingProject.number} - ${matchingProject.name}`,
                    }
                  : null
              }
              onChange={(option) => {
                setProjectId(option.value);
              }}
            />
            <p style={{ fontSize: 12 }}>Category 1</p>
            <Select
              placeholder="Select Category 1"
              isDisabled={!!partId}
              className="rs__select"
              classNamePrefix="react-select"
              options={
                categories
                  ? categories
                      .filter((cat) => !cat.parent_category_id)
                      .map((cat) => ({ label: `${cat.number} - ${cat.name}`, value: cat.id }))
                  : []
              }
              value={
                matchingCat1
                  ? {
                      value: matchingCat1.id,
                      label: `${matchingCat1.number} - ${matchingCat1.name}`,
                    }
                  : null
              }
              onChange={(option) => {
                setCat1Id(option.value);
                setCat2Id("");
                setCat3Id("");
              }}
            />
            {matchingCat1 && (
              <>
                <p style={{ fontSize: 12 }}>Category 2</p>
                <Select
                  placeholder="Select Category 2"
                  isDisabled={!!partId}
                  className="rs__select"
                  classNamePrefix="react-select"
                  options={
                    categories
                      ? categories
                          .filter((cat) => cat.parent_category_id === matchingCat1.id)
                          .map((cat) => ({ label: `${cat.number} - ${cat.name}`, value: cat.id }))
                      : []
                  }
                  value={
                    matchingCat2
                      ? {
                          value: matchingCat2.id,
                          label: `${matchingCat2.number} - ${matchingCat2.name}`,
                        }
                      : null
                  }
                  onChange={(option) => {
                    setCat2Id(option.value);
                    setCat3Id("");
                  }}
                />
              </>
            )}
            {matchingCat2 && (
              <>
                <p style={{ fontSize: 12 }}>Category 3</p>
                <Select
                  placeholder="Select Category 3"
                  isDisabled={!!partId}
                  className="rs__select"
                  classNamePrefix="react-select"
                  options={
                    categories
                      ? categories
                          .filter((cat) => cat.parent_category_id === matchingCat2.id)
                          .map((cat) => ({ label: `${cat.number} - ${cat.name}`, value: cat.id }))
                      : []
                  }
                  value={
                    matchingCat3
                      ? {
                          value: matchingCat3.id,
                          label: `${matchingCat3.number} - ${matchingCat3.name}`,
                        }
                      : null
                  }
                  onChange={(option) => {
                    setCat3Id(option.value);
                  }}
                />
              </>
            )}
            <p style={{ fontSize: 12 }}>Running number</p>
            <Select
              placeholder="Select running number"
              className="rs__select"
              isDisabled={!projectId || !cat1Id || !cat2Id || !cat3Id || !!partId}
              classNamePrefix="react-select"
              options={[...new Set(matchingParts.map((part) => part.running_number))]
                .map((rn) => ({
                  value: rn,
                  label: getRunningNumber(rn),
                }))
                .concat({
                  value: 0,
                  label: "NEW",
                })}
              value={{ value: runningNumber || 0, label: getRunningNumber(runningNumber) }}
              onChange={(option) => {
                setRunningNumber(option.value);
              }}
            />
            {runningNumber > 0 && (
              <>
                <p style={{ fontSize: 12 }}>Part index</p>
                <Select
                  placeholder="Select part index"
                  isDisabled={!!partId}
                  className="rs__select"
                  classNamePrefix="react-select"
                  options={_.orderBy(
                    [
                      ...new Set(
                        matchingParts
                          .filter((p) => p.running_number === String(runningNumber))
                          .map((part) => part.part_index)
                      ),
                    ].map((pi) => ({
                      value: pi,
                      label: pi,
                    })),
                    "value",
                    ["asc"]
                  ).concat({
                    value: "ZZZ",
                    label: "NEW",
                  })}
                  value={{
                    value: partIndex || "ZZZ",
                    label: !partIndex || partIndex === "ZZZ" ? "NEW" : partIndex,
                  }}
                  onChange={(option) => {
                    setPartIndex(option.value);
                  }}
                />
              </>
            )}
            {partId && (
              <>
                <Grid item xs={3} sm={12} style={{ zIndex: -1 }}>
                  <MDInput
                    fullWidth
                    style={{ zIndex: 0 }}
                    disabled
                    label="Drawing index"
                    value={drawingIndex}
                    onChange={(e) => setDrawingIndex(e.target.value)}
                  />
                </Grid>
                <div style={{ height: 15 }} />
              </>
            )}
            <Grid item xs={3} sm={12} style={{ zIndex: -1 }}>
              <MDInput
                fullWidth
                style={{ zIndex: 0 }}
                disabled={reviewStatus === "approved"}
                label="SVN Revision number"
                value={svn}
                onChange={(e) => setSvn(e.target.value)}
              />
            </Grid>
            <div style={{ height: 15 }} />
            <Grid item xs={3} sm={12} style={{ zIndex: -1 }}>
              <MDInput
                fullWidth
                disabled={(!partId && runningNumber > 0) || reviewStatus === "approved"}
                style={{ zIndex: 0 }}
                label="Name*"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <div style={{ height: 15 }} />
            <p style={{ fontSize: 12 }}>CAD System</p>
            <Select
              placeholder="Select CAD System"
              className="rs__select"
              isDisabled={reviewStatus === "approved"}
              classNamePrefix="react-select"
              options={
                cadSystems
                  ? cadSystems.map((cadSystem) => ({ label: cadSystem.name, value: cadSystem.id }))
                  : []
              }
              value={
                matchingCadSystem
                  ? { value: matchingCadSystem.id, label: matchingCadSystem.name }
                  : null
              }
              onChange={(option) => {
                setCadSystemId(option.value);
              }}
            />
            <p style={{ fontSize: 12 }}>Key words</p>
            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              readOnly={reviewStatus === "approved"}
              handleDrag={handleDrag}
              inputFieldPosition="top"
              autofocus={false}
              suggestions={suggestions}
            />
            {isGuest ? (
              ""
            ) : (
              <>
                <div style={{ height: 15 }} />
                <Grid item xs={12} sm={12}>
                  {reviewStatus !== "approved" && (
                    <>
                      <MDButton
                        variant="gradient"
                        type="submit"
                        color="success"
                        style={{ minWidth: 130 }}
                      >
                        Save
                      </MDButton>
                      {isAdmin && (
                        <MDButton
                          variant="gradient"
                          color="error"
                          style={{ minWidth: 130, marginLeft: 10 }}
                          onClick={deleteHandler}
                        >
                          Delete
                        </MDButton>
                      )}
                    </>
                  )}
                  <MDButton
                    variant="gradient"
                    type="submit"
                    color="info"
                    style={{ minWidth: 130, marginLeft: reviewStatus !== "approved" ? 10 : 0 }}
                    onClick={newVersionHandler}
                  >
                    New Version
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    type="submit"
                    color="info"
                    style={{ minWidth: 130, marginLeft: 10 }}
                    onClick={newRevisionHandler}
                  >
                    New Revision
                  </MDButton>
                </Grid>
                <div style={{ height: 150 }} />
              </>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

export default PartEdit;
