// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useQueryClient } from "react-query";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import { useNavigate } from "react-router-dom";

// Data
import { useGetCadSystems, useAddCadSystem } from "hooks/useQueries";
import { Grid } from "@mui/material";
import MDInput from "components/MDInput";
import { useState } from "react";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import Modal from "react-modal";

Modal.setAppElement("#root");

function CadSystems() {
  const { data: cadSystems } = useGetCadSystems();
  const { mutateAsync: addCadSystem } = useAddCadSystem();
  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [name, setName] = useState("");

  const cadSystemDataTable = {
    columns: [
      { Header: "Id", accessor: "id" },
      { Header: "Name", accessor: "name" },
    ],

    rows: cadSystems
      ? cadSystems.map((cadSystem) => ({
          id: cadSystem.id,
          name: cadSystem.name,
        }))
      : [],
  };

  const cadSystemClick = (id) => {
    navigate(`/admin/cad/${id}`);
  };

  const submitCadSystemHandler = async (e) => {
    e.preventDefault();
    if (!name) {
      toast.warning("Please enter all required fields");
      return;
    }
    try {
      await addCadSystem({
        name,
      });
      setName("");
      setModalIsOpen(false);
      toast.success("CAD System added!");
      queryCache.invalidateQueries("getCadSystems");
    } catch (err) {
      if (err.response?.data?.message) {
        toast.warning(err.response?.data?.message);
      } else {
        toast.warning("Error, something went wrong");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="New CAD System"
        style={{
          overlay: {
            backgroundColor: "#00000085",
            zIndex: 10000,
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 500,
          },
        }}
      >
        <MDBox p={3} sm={12}>
          <MDTypography variant="h5">Add new CAD System</MDTypography>
        </MDBox>
        <MDBox component="form" onSubmit={submitCadSystemHandler}>
          <Grid item xs={3} sm={12}>
            <MDInput
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <div style={{ height: 10 }} />
          <Grid item xs={12} sm={12}>
            <MDButton variant="gradient" type="submit" color="info" style={{ minWidth: 130 }}>
              Save
            </MDButton>
          </Grid>
        </MDBox>
      </Modal>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <div style={{ flex: 1 }}>
                  <MDTypography variant="h5" fontWeight="medium">
                    CAD Systems
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    Sort by clicking the columns, filter by using the search field
                  </MDTypography>
                </div>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => setModalIsOpen(true)}
                  style={{ minWidth: 130, marginLeft: 20 }}
                >
                  New CAD System
                </MDButton>
              </div>
            </MDBox>
            <DataTable table={cadSystemDataTable} canSearch onClick={cadSystemClick} />
          </Card>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default CadSystems;
