import { useQuery, useMutation } from "react-query";
import { API } from "../api";

const AuthUrl = process.env.REACT_APP_AUTH_URL;
const ApiUrl = process.env.REACT_APP_API_URL;

// user account
export function useReset(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${AuthUrl}/reset`, body);
    return result.data;
  }, props?.options);
}

export function useEnableMFA(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${AuthUrl}/users/${props.id}/mfa/enable`, body);
    return result.data;
  }, props?.options);
}

export function useConfirmMFA(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${AuthUrl}/users/${props.id}/mfa/confirm`, body);
    return result.data;
  }, props?.options);
}

// admin
export function useGetAdminUsers(props) {
  return useQuery(
    `getAdminUsers`,
    async () => {
      const result = await API.get(`${AuthUrl}/users`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useGetAdminSingleUser(props) {
  return useQuery(
    `getAdminSingleUser-${props.id}`,
    async () => {
      const result = await API.get(`${AuthUrl}/users/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useGetAdminRoles(props) {
  return useQuery(
    `getAdminRoles`,
    async () => {
      const result = await API.get(`${AuthUrl}/roles`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useAdminAddUser(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${AuthUrl}/users`, body);
    return result.data;
  }, props?.options);
}

export function useAdminUpdateUser(props) {
  return useMutation(async (body) => {
    const result = await API.put(`${AuthUrl}/users/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useAdminDeleteUser(props) {
  return useMutation(async (body) => {
    const result = await API.delete(`${AuthUrl}/users/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useAdminInviteUser(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${AuthUrl}/invite`, body);
    return result.data;
  }, props?.options);
}

// projects
export function useGetProjects(props) {
  return useQuery(
    `getProjects`,
    async () => {
      const result = await API.get(`${ApiUrl}/projects`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useAddProject(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/projects`, body);
    return result.data;
  }, props?.options);
}

export function useGetSingleProject(props) {
  return useQuery(
    `getSingleProject-${props.id}`,
    async () => {
      const result = await API.get(`${ApiUrl}/projects/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useUpdateProject(props) {
  return useMutation(async (body) => {
    const result = await API.put(`${ApiUrl}/projects/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useDeleteProject(props) {
  return useMutation(async (body) => {
    const result = await API.delete(`${ApiUrl}/projects/${props.id}`, body);
    return result.data;
  }, props?.options);
}

// categories
export function useGetCategories(props) {
  return useQuery(
    `getCategories`,
    async () => {
      const result = await API.get(`${ApiUrl}/categories`);
      return result.data.sort((a, b) => a.number - b.number);
    },
    { ...props?.options }
  );
}

export function useAddCategory(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/categories`, body);
    return result.data;
  }, props?.options);
}

export function useGetSingleCategory(props) {
  return useQuery(
    `getSingleCategory-${props.id}`,
    async () => {
      const result = await API.get(`${ApiUrl}/categories/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useUpdateCategory(props) {
  return useMutation(async (body) => {
    const result = await API.put(`${ApiUrl}/categories/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useDeleteCategory(props) {
  return useMutation(async (body) => {
    const result = await API.delete(`${ApiUrl}/categories/${props.id}`, body);
    return result.data;
  }, props?.options);
}

// cad systems
export function useGetCadSystems(props) {
  return useQuery(
    `getCadSystems`,
    async () => {
      const result = await API.get(`${ApiUrl}/cad-systems`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useAddCadSystem(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/cad-systems`, body);
    return result.data;
  }, props?.options);
}

export function useGetSingleCadSystem(props) {
  return useQuery(
    `getSingleCadSystem-${props.id}`,
    async () => {
      const result = await API.get(`${ApiUrl}/cad-systems/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useUpdateCadSystem(props) {
  return useMutation(async (body) => {
    const result = await API.put(`${ApiUrl}/cad-systems/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useDeleteCadSystem(props) {
  return useMutation(async (body) => {
    const result = await API.delete(`${ApiUrl}/cad-systems/${props.id}`, body);
    return result.data;
  }, props?.options);
}

// parts
export function useGetParts(props) {
  return useQuery(
    `getParts`,
    async () => {
      const result = await API.get(`${ApiUrl}/parts`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useAddPart(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/parts`, body);
    return result.data;
  }, props?.options);
}

export function useAddPartNewVersion(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/parts/${props.id}/new-version`, body);
    return result.data;
  }, props?.options);
}

export function useAddPartNewRevision(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/parts/${props.id}/new-revision`, body);
    return result.data;
  }, props?.options);
}

export function useGetSinglePart(props) {
  return useQuery(
    `getSinglePart-${props.id}`,
    async () => {
      const result = await API.get(`${ApiUrl}/parts/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useUpdatePart(props) {
  return useMutation(async (body) => {
    const result = await API.put(`${ApiUrl}/parts/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useDeletePart(props) {
  return useMutation(async (body) => {
    const result = await API.delete(`${ApiUrl}/parts/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useApprovePart(props) {
  return useMutation(async () => {
    const result = await API.post(`${ApiUrl}/parts/${props.id}/approve`, { entity_id: props.id });
    return result.data;
  }, props?.options);
}

export function useRejectPart(props) {
  return useMutation(async () => {
    const result = await API.post(`${ApiUrl}/parts/${props.id}/reject`, { entity_id: props.id });
    return result.data;
  }, props?.options);
}

export function useArchivePart(props) {
  return useMutation(async () => {
    const result = await API.post(`${ApiUrl}/parts/${props.id}/archive`, { entity_id: props.id });
    return result.data;
  }, props?.options);
}

export function useLockPart(props) {
  return useMutation(async () => {
    const result = await API.post(`${ApiUrl}/parts/${props.id}/lock`, { entity_id: props.id });
    return result.data;
  }, props?.options);
}

export function useUnlockPart(props) {
  return useMutation(async () => {
    const result = await API.post(`${ApiUrl}/parts/${props.id}/unlock`, { entity_id: props.id });
    return result.data;
  }, props?.options);
}

// notes
export function useGetNotes(props) {
  return useQuery(
    `getNotes`,
    async () => {
      const result = await API.get(`${ApiUrl}/notes`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useAddNote(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/notes`, body);
    return result.data;
  }, props?.options);
}

export function useGetSingleNote(props) {
  return useQuery(
    `getSingleNote-${props.id}`,
    async () => {
      const result = await API.get(`${ApiUrl}/notes/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useUpdateNote(props) {
  return useMutation(async (body) => {
    const result = await API.put(`${ApiUrl}/notes/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useDeleteNote(props) {
  return useMutation(async (body) => {
    const result = await API.delete(`${ApiUrl}/notes/${props.id}`, body);
    return result.data;
  }, props?.options);
}

// events
export function useGetEvents(props) {
  return useQuery(
    `getEvents`,
    async () => {
      const result = await API.get(`${ApiUrl}/events`);
      return result.data;
    },
    { ...props?.options }
  );
}

// files
export function useGetFiles(props) {
  return useQuery(
    `getFiles`,
    async () => {
      const result = await API.get(`${ApiUrl}/files`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useAddFile(props) {
  return useMutation(async (body) => {
    const result = await API.post(`${ApiUrl}/files`, body);
    return result.data;
  }, props?.options);
}

export function useGetSingleFile(props) {
  return useQuery(
    `getSingleFile-${props.id}`,
    async () => {
      const result = await API.get(`${ApiUrl}/files/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}

export function useUpdateFile(props) {
  return useMutation(async (body) => {
    const result = await API.put(`${ApiUrl}/files/${props.id}`, body);
    return result.data;
  }, props?.options);
}

export function useDeleteFile(props) {
  return useMutation(async (body) => {
    const result = await API.delete(`${ApiUrl}/files/${body}`);
    return result.data;
  }, props?.options);
}

export function useGetFileReference(props) {
  return useQuery(
    `getFileReference-${props.id}`,
    async () => {
      const result = await API.get(`${ApiUrl}/file/${props.id}`);
      return result.data;
    },
    { ...props?.options }
  );
}
