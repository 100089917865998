import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import App from "App";
import { toast, Zoom } from "react-toastify";
import { QueryClientProvider } from "react-query";
import "./index.scss";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import AuthProvider from "context/AuthProvider";
import { theQueryClient } from "./api";

toast.configure({
  autoClose: 3000,
  transition: Zoom,
});

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AuthProvider>
        <QueryClientProvider client={theQueryClient}>
          <App />
        </QueryClientProvider>
      </AuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
