// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  useGetSinglePart,
  useGetAdminUsers,
  useApprovePart,
  useRejectPart,
  useAddNote,
  useUpdateNote,
  useDeleteNote,
  useLockPart,
  useUnlockPart,
  useArchivePart,
  useAddFile,
  useGetFiles,
  useDeleteFile,
} from "hooks/useQueries";

// Data
import { useParams } from "react-router-dom";
import PartEdit from "components/PartEdit/PartEdit";
import Div100vh from "react-div-100vh";
import Loader from "react-spinners/ClipLoader";
import { Grid, Icon, Table, TableBody, TableRow } from "@mui/material";
import DataTableBodyCell from "components/DataTable/DataTableBodyCell";
import DataTableHeadCell from "components/DataTable/DataTableHeadCell";
import MDButton from "components/MDButton";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import _ from "lodash";
import MDEditor from "components/MDEditor";
import { useState, useRef } from "react";
import "./Part.scss";
import TimelineList from "examples/Timeline/TimelineList";
import timelineItem from "examples/Timeline/TimelineItem/styles";
import { useAuth } from "context/AuthProvider";
import { API } from "api";

const ApiUrl = process.env.REACT_APP_API_URL;

function Note({ note, lastItem, partId }) {
  const { user } = useAuth();
  const queryCache = useQueryClient();
  const { data: users } = useGetAdminUsers();
  const [editMode, setEditMode] = useState(false);
  const [editorValue, setEditorValue] = useState(note.text);
  const { mutateAsync: updateNote } = useUpdateNote({ id: note.id });
  const { mutateAsync: deleteNote } = useDeleteNote({ id: note.id });
  const isGuest = user?.roles?.includes("guest");

  const matchingUser = users?.find((u) => u.id === note.user_id);

  const ownNote = note.user_id === user?.sub;

  const saveNoteHandler = async () => {
    if (!editorValue) {
      toast.warning("Add a note text");
      return;
    }

    await updateNote({
      ...note,
      text: editorValue,
    });
    toast.success("Note updated!");
    setEditMode(false);
    queryCache.invalidateQueries("getNotes");
    queryCache.invalidateQueries(`getSinglePart-${partId}`);
  };

  const deleteHandler = () => {
    confirmAlert({
      title: "Are you sure?",
      message: "Really delete this note?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deleteNote();
            toast.success("Note deleted!");
            queryCache.invalidateQueries("getNotes");
            queryCache.invalidateQueries(`getSinglePart-${partId}`);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <MDBox
      position="relative"
      mb={3}
      sx={(theme) => timelineItem(theme, { lastItem, isDark: false })}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={
          note.type === "note"
            ? `${ownNote ? "primary" : "secondary"}`
            : `${
                note.text.includes("Approved")
                  ? "success"
                  : `${note.text.includes("lock") ? "info" : "error"}`
              }`
        }
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">
          {note.type === "note"
            ? "person"
            : `${
                note.text.includes("Approved")
                  ? "check"
                  : `${note.text.includes("lock") ? "lock" : "error"}`
              }`}
        </Icon>
      </MDBox>
      <MDBox ml={5.75} pt={0.7} lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color="dark">
          {note.type === "note"
            ? matchingUser.email
            : `${
                note.text.includes("Approved")
                  ? "Approved"
                  : `${note.text.includes("lock") ? "Lock event" : "Rejected"}`
              }`}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color="text">
            {new Date(note.timestamp).toLocaleDateString(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {editMode ? (
            <>
              <MDEditor value={editorValue} onChange={setEditorValue} />
              <MDButton
                variant="gradient"
                color="info"
                onClick={saveNoteHandler}
                style={{ minWidth: 130, marginTop: 15 }}
              >
                Update note
              </MDButton>
              <MDButton
                variant="gradient"
                color="secondary"
                onClick={() => setEditMode(false)}
                style={{ minWidth: 130, marginTop: 15, marginLeft: 15 }}
              >
                Cancel update note
              </MDButton>
            </>
          ) : (
            <>
              <MDTypography variant="button" color="dark">
                <div dangerouslySetInnerHTML={{ __html: note.text }} />
              </MDTypography>
              {!isGuest && ownNote && note.type === "note" && (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setEditMode(true)}
                    style={{ minWidth: 130, marginTop: 15 }}
                  >
                    Edit note
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="error"
                    onClick={deleteHandler}
                    style={{ minWidth: 130, marginTop: 15, marginLeft: 15 }}
                  >
                    Delete note
                  </MDButton>
                </>
              )}
            </>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

function OpenPart() {
  const params = useParams();
  const queryCache = useQueryClient();
  const { user } = useAuth();
  const { partId } = params;
  const { mutateAsync: addNote } = useAddNote();
  const { mutateAsync: approvePart } = useApprovePart({ id: partId });
  const { mutateAsync: rejectPart } = useRejectPart({ id: partId });
  const { mutateAsync: lockPart } = useLockPart({ id: partId });
  const { mutateAsync: unlockPart } = useUnlockPart({ id: partId });
  const { mutateAsync: acrhivePart } = useArchivePart({ id: partId });
  const { data: users } = useGetAdminUsers();
  const { mutateAsync: addFile, isLoading: uploading } = useAddFile();
  const { mutateAsync: deleteFile } = useDeleteFile();
  const [selectedFile, setSelectedFile] = useState();
  const uploadInputRef = useRef();
  const isGuest = user?.roles?.includes("guest");

  const { data: selectedPart, isLoading } = useGetSinglePart({
    id: partId,
    options: { enabled: !!partId },
  });

  const { data: files } = useGetFiles();

  const [editorValue, setEditorValue] = useState("");

  const notesAndEventsCombined = selectedPart?.notes
    ?.map((note) => ({ ...note, type: "note" } || []))
    .concat(selectedPart?.events?.map((event) => ({ ...event, type: "event" })) || []);

  const sortedNotesAndEvents = _.orderBy(notesAndEventsCombined, "timestamp", ["desc"]);

  const matchingUser =
    selectedPart && users ? users.find((u) => u.id === selectedPart.user_id) : null;

  const lockUser =
    selectedPart?.locked_by && users ? users.find((u) => u.id === selectedPart?.locked_by) : null;

  const lockedByCurrentUser = user?.sub === selectedPart?.locked_by;

  let reviewStatus = "draft";

  if (
    selectedPart?.reviewers &&
    selectedPart.reviewers.filter((review) => review.status === "approved").length >= 3
  ) {
    reviewStatus = "approved";
  } else if (selectedPart?.reviewers) {
    reviewStatus = selectedPart?.stage;
  }

  const approveRejectHandler = (approve) => {
    if (selectedPart.stage === "stage_3" && !user?.roles?.includes("lead-designer")) {
      toast.warning("Lead designer role needed to review in this stage");
      return;
    }
    confirmAlert({
      title: "Are you sure?",
      message: approve
        ? `${
            selectedPart.stage === "archived" ? "Unarchive this dataset?" : "Approve this dataset?"
          }`
        : `${selectedPart.stage === "stage_1" ? "Archive this dataset?" : "Reject this dataset?"}`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            if (approve) {
              await approvePart();
              toast.success("Dataset approved!");
            } else if (selectedPart.stage === "stage_1") {
              await acrhivePart();
              toast.success("Dataset archived!");
            } else {
              await rejectPart();
              toast.success("Dataset rejected!");
            }

            queryCache.invalidateQueries(`getSinglePart-${partId}`);
            queryCache.invalidateQueries("getParts");
            queryCache.invalidateQueries("getEvents");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const lockHandler = async (lock) => {
    if (lock) {
      await lockPart();
      toast.success("Dataset locked!");
    } else {
      await unlockPart();
      toast.success("Dataset unlocked!");
    }
    queryCache.invalidateQueries(`getSinglePart-${partId}`);
    queryCache.invalidateQueries("getParts");
    queryCache.invalidateQueries("getEvents");
  };

  const addNoteHandler = async () => {
    if (!editorValue) {
      toast.warning("Add a note text");
      return;
    }

    await addNote({
      text: editorValue,
      project_id: selectedPart.project_id,
      part_id: partId,
    });
    toast.success("Note added!");
    setEditorValue("");
    queryCache.invalidateQueries("getNotes");
    queryCache.invalidateQueries(`getSinglePart-${partId}`);
  };

  const onFileChange = (event) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        setSelectedFile(reader.result.replace("data:application/pdf;base64,", ""));
      },
      false
    );

    reader.readAsDataURL(event.target.files[0]);
  };

  const startFileUpload = async () => {
    try {
      await addFile({
        part_id: partId,
        file_data: selectedFile,
      });
      queryCache.invalidateQueries(`getSinglePart-${partId}`);
      queryCache.invalidateQueries("getFiles");
      toast.success("PDF uploaded!");
      uploadInputRef.current.value = "";
      setSelectedFile(undefined);
    } catch (err) {
      setSelectedFile(undefined);
      uploadInputRef.current.value = "";
      if (err.response?.data?.message) {
        toast.warning(err.response?.data?.message);
      } else {
        toast.warning("Error, something went wrong");
      }
    }
  };

  const openFile = (reference, name, download) => {
    API({
      url: `${ApiUrl}/file/${reference}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      if (download) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      } else {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  };

  const deleteFileHandler = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Really delete this pdf?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deleteFile(id);
            toast.success("File deleted!");
            queryCache.invalidateQueries(`getSinglePart-${partId}`);
            queryCache.invalidateQueries("getFiles");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  if (isLoading) {
    return (
      <Div100vh className="not-logged-in">
        <Loader />
      </Div100vh>
    );
  }

  if (!selectedPart) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Dataset not found
              </MDTypography>
              <MDTypography variant="button" color="text">
                Cannot find dataset with id: {partId}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customCrumb={selectedPart.name} />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}>
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography variant="h5">{`Dataset: ${selectedPart.name}`}</MDTypography>
                </MDBox>
                <Card sx={{ boxShadow: "none" }}>
                  <PartEdit partId={partId} noHeader finishHandler={() => {}} />
                </Card>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Card style={{ marginBottom: 30 }}>
                <MDBox alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h5">Review Status</MDTypography>
                  {selectedPart.locked_by && (
                    <p style={{ marginTop: 20 }}>
                      <strong>{`Locked by ${lockUser?.email}`}</strong>
                    </p>
                  )}
                </MDBox>
                <table style={{ margin: 15 }}>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <strong>Designer:</strong>
                        </p>
                      </td>
                      <td>
                        <p>{matchingUser?.email}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Status:</strong>
                        </p>
                      </td>
                      <td>
                        <p>{reviewStatus}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography variant="h6">Reviewers</MDTypography>
                </MDBox>
                <Table>
                  <MDBox component="thead">
                    <TableRow>
                      <DataTableHeadCell sorted={false}> </DataTableHeadCell>
                      <DataTableHeadCell sorted={false}>Date</DataTableHeadCell>
                      <DataTableHeadCell sorted={false}>Reviewer</DataTableHeadCell>
                      <DataTableHeadCell sorted={false}>Stage</DataTableHeadCell>
                      <DataTableHeadCell sorted={false}>Response</DataTableHeadCell>
                    </TableRow>
                  </MDBox>
                  <TableBody>
                    {selectedPart?.reviewers?.map((row) => (
                      <TableRow key={row.id}>
                        <DataTableBodyCell>
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgColor={row.status === "approved" ? "success" : "error"}
                            color="white"
                            width="2rem"
                            height="2rem"
                            borderRadius="50%"
                            top="8%"
                            left="2px"
                            zIndex={2}
                            sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                          >
                            <Icon fontSize="inherit">
                              {row.status === "approved" ? "check" : "error"}
                            </Icon>
                          </MDBox>
                        </DataTableBodyCell>
                        <DataTableBodyCell>
                          {new Date(row.timestamp).toLocaleDateString(undefined, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </DataTableBodyCell>
                        <DataTableBodyCell>
                          {users?.find((u) => u.id === row.user_id)?.email}
                        </DataTableBodyCell>
                        <DataTableBodyCell>{row.stage}</DataTableBodyCell>
                        <DataTableBodyCell>{row.status}</DataTableBodyCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {isGuest ? (
                  ""
                ) : (
                  <Grid item xs={12} sm={12} style={{ margin: 15 }}>
                    {selectedPart.locked_by ? (
                      <>
                        {lockedByCurrentUser ? (
                          <>
                            <MDButton
                              variant="gradient"
                              color="success"
                              onClick={() => approveRejectHandler(true)}
                              style={{ minWidth: 130, marginRight: 15 }}
                            >
                              {selectedPart.stage === "archived" ? "Unarchive" : "Approve"}
                            </MDButton>
                            <MDButton
                              variant="gradient"
                              color="error"
                              onClick={() => approveRejectHandler(false)}
                              style={{ minWidth: 130, marginRight: 15 }}
                            >
                              {selectedPart.stage === "stage_1" ? "Archive" : "Reject"}
                            </MDButton>
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => lockHandler(false)}
                              style={{ minWidth: 130 }}
                            >
                              Unlock
                            </MDButton>
                          </>
                        ) : (
                          <>
                            <h6>Only {lockUser?.email} can unlock this dataset</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {reviewStatus !== "approved" && (
                          <MDButton
                            variant="gradient"
                            color="info"
                            onClick={() => lockHandler(true)}
                            style={{ minWidth: 130 }}
                          >
                            Lock for approval or rejection
                          </MDButton>
                        )}
                      </>
                    )}
                  </Grid>
                )}
              </Card>
              <Card>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography variant="h5">Attached documents</MDTypography>
                </MDBox>
                <Card sx={{ boxShadow: "none" }} style={{ paddingTop: 20 }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        <DataTableHeadCell sorted={false}>File</DataTableHeadCell>
                        <DataTableHeadCell sorted={false}> </DataTableHeadCell>
                        <DataTableHeadCell sorted={false}> </DataTableHeadCell>
                        <DataTableHeadCell sorted={false}> </DataTableHeadCell>
                        {reviewStatus !== "archived" && (
                          <DataTableHeadCell sorted={false}> </DataTableHeadCell>
                        )}
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {_.orderBy(
                        files?.filter((file) => file.part_id === partId),
                        "index_number",
                        ["asc"]
                      )?.map((row) => (
                        <TableRow key={row.id}>
                          <DataTableBodyCell>{`${selectedPart.part_number}---${row.index_number}.pdf`}</DataTableBodyCell>
                          <DataTableBodyCell>
                            <MDButton
                              variant="gradient"
                              size="small"
                              color="info"
                              onClick={() =>
                                openFile(
                                  row.file_reference,
                                  `${selectedPart.part_number}---${row.index_number}.pdf`,
                                  true
                                )
                              }
                            >
                              Download
                            </MDButton>
                          </DataTableBodyCell>
                          <DataTableBodyCell>
                            <MDButton
                              variant="gradient"
                              size="small"
                              color="info"
                              onClick={() =>
                                openFile(
                                  row.file_reference,
                                  `${selectedPart.part_number}---${row.index_number}.pdf`,
                                  false
                                )
                              }
                            >
                              Open
                            </MDButton>
                          </DataTableBodyCell>
                          <DataTableBodyCell>
                            {!isGuest &&
                              reviewStatus !== "archived" &&
                              reviewStatus !== "approved" && (
                                <MDButton
                                  variant="gradient"
                                  size="small"
                                  color="error"
                                  onClick={() => deleteFileHandler(row.id)}
                                >
                                  Delete
                                </MDButton>
                              )}
                          </DataTableBodyCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {!isGuest && reviewStatus !== "approved" && (
                    <div style={{ padding: 20 }}>
                      <MDBox
                        component="input"
                        name="file"
                        type="file"
                        onChange={onFileChange}
                        ref={uploadInputRef}
                        accept="application/pdf"
                      />
                      {uploading && <Loader />}
                      {selectedFile && !uploading && (
                        <MDButton variant="gradient" color="success" onClick={startFileUpload}>
                          Upload file
                        </MDButton>
                      )}
                    </div>
                  )}
                </Card>
              </Card>

              <Card style={{ marginTop: 30 }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography variant="h5">Notes and events</MDTypography>
                </MDBox>
                {!isGuest && reviewStatus !== "approved" && (
                  <div className="note-editor">
                    <MDTypography variant="h6">Add new note</MDTypography>
                    <MDEditor value={editorValue} onChange={setEditorValue} />
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={addNoteHandler}
                      style={{ minWidth: 130, marginTop: 15 }}
                    >
                      Add note
                    </MDButton>
                  </div>
                )}
                {sortedNotesAndEvents && sortedNotesAndEvents.length > 0 && (
                  <TimelineList title="Notes and events:">
                    {sortedNotesAndEvents?.map((note, index) => (
                      <Note
                        note={note}
                        lastItem={index === sortedNotesAndEvents.length - 1}
                        key={note.id}
                        partId={partId}
                      />
                    ))}
                  </TimelineList>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default OpenPart;
