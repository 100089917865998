import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./Loader.scss";

const Loader = () => (
  <div className="loader">
    <ClipLoader loading size={50} />
  </div>
);

export default Loader;
