import { useEffect, useState } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
import { toast } from "react-toastify";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/heatpump-bg.jpg";
import MDAlert from "components/MDAlert";
import ClipLoader from "react-spinners/ClipLoader";

const zxcvbn = require("zxcvbn");

function Confirm() {
  const [busy, setBusy] = useState(true);
  const [error, setError] = useState("");
  const [passwordFase, setPasswordFase] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const { token } = params;

  const savePasswordHandler = (e) => {
    e.preventDefault();

    if (busy) {
      //
    } else if (!newPassword || !confirmPassword) {
      toast.warning("Enter your new password twice");
    } else if (newPassword !== confirmPassword) {
      toast.warning("New password and confirm new password don't match");
    } else if (zxcvbn(newPassword).score < 2) {
      toast.warning(
        "Your new password is to weak. Use lower- and upper case, numbers and special characters."
      );
    } else {
      setBusy(true);
      axios
        .post(
          `${process.env.REACT_APP_AUTH_URL}/password`,
          { password: newPassword },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          toast.success("Account successfully activated!");
          navigate("/login");
        })
        .catch((fberror) => {
          if (fberror.response?.data?.message) {
            toast.warning(fberror.response?.data?.message);
            setError(fberror.response?.data?.message);
          } else {
            toast.warning(
              "Error setting new password. Please try again or contact the administrator."
            );
          }
          setBusy(false);
        });
    }
  };

  useEffect(() => {
    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_AUTH_URL}/activate`,
          { token },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          setPasswordFase(true);
          setBusy(false);
        })
        .catch((fberror) => {
          if (fberror.response?.data?.message) {
            toast.warning(fberror.response?.data?.message);
            setError(fberror.response?.data?.message);
          } else {
            toast.warning(
              "Error: This link appears to be invalid. Please try again or contact the administrator."
            );
          }
          setBusy(false);
        });
    } else {
      setError("This link appears to be invalid. Please try again or contact the administrator.");
      setBusy(false);
    }
  }, [token, navigate]);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Confirm login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {busy && !passwordFase && <ClipLoader loading size={50} />}
          {error && <MDAlert color="primary">{error}</MDAlert>}
          {passwordFase && (
            <form className="MuiBox-root css-1ircn5c" onSubmit={savePasswordHandler}>
              <p>Fill in your new password</p>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="Pick a new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  fullWidth
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                {busy ? (
                  <ClipLoader loading size={50} />
                ) : (
                  <MDButton variant="gradient" color="info" fullWidth type="submit">
                    Save new password
                  </MDButton>
                )}
              </MDBox>
            </form>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Confirm;
