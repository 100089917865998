// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
// Authentication layout components

// Images
import bgImage from "assets/images/heatpump-bg.jpg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useState } from "react";
import MDAlert from "components/MDAlert";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState("");
  const [success, setSuccess] = useState(false);
  const [busy, setBusy] = useState(false);

  const loginHandler = (event) => {
    event.preventDefault();
    if (email.length > 0 && !busy) {
      setBusy(true);
      setFormError("");
      axios
        .post(`${process.env.REACT_APP_AUTH_URL}/reset`, { email })
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            toast.warning(error.response?.data?.message);
            setFormError(error.response?.data?.message);
          } else {
            toast.warning("Error, something went wrong! Please try again");
          }
          setBusy(false);
        });
    } else if (!email) {
      toast.warning("Enter your email");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {success ? (
            <>
              <MDAlert color="success">
                You&apos;ve received a password reset link in your email
              </MDAlert>
            </>
          ) : (
            <MDBox component="form" role="form" onSubmit={loginHandler}>
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              {formError && <MDAlert color="error">{formError}</MDAlert>}
              <MDBox mt={6} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  reset
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              <MDTypography
                component={Link}
                to="/login"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Back to login
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
