import axios from "axios";
import { QueryClient } from "react-query";

export const errorReporting = (error) => {
  if (error.response) {
    // report all 4XX errors and 500 errors
    if (error?.response?.status > 399 && error?.response?.status < 501) {
      console.log("API error", error.response);
    }
  }
};

export const theQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: errorReporting,
      cacheTime: 60000,
      retry: (failureCount, error) => {
        if (error?.response?.status) {
          if (error?.response?.status > 399 && error?.response?.status < 500) {
            return false;
          }
        }
        if (failureCount > 3) {
          return false;
        }
        return true;
      },
    },
    mutations: {
      onError: errorReporting,
    },
  },
});

export const API = axios.create();
