import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes, { adminRoutes, guestRoutes } from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/favicon.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useAuth } from "context/AuthProvider";
import Div100vh from "react-div-100vh";
import Login from "layouts/authentication/sign-in";
import ForgotPassword from "layouts/authentication/reset-password/cover";
import Confirm from "layouts/authentication/confirm";
import User from "layouts/admin/users/user";
import Project from "layouts/admin/projects/project";
import Categories from "layouts/admin/categories/categories";
import CadSystem from "layouts/admin/cadSystems/cadSystem";
import AdminNewPart from "layouts/admin/projects/NewPart";
import NewPart from "layouts/regular/part/NewPart";
import OpenPart from "layouts/regular/part/OpenPart";
import Loader from "./components/Loader/Loader";

export default function App() {
  const { user, loading } = useAuth();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const brandName = "Mímir";

  const isAdmin = user?.roles?.includes("admin");
  const isGuest = user?.roles?.includes("guest");

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  if (loading) {
    return (
      <Div100vh className="not-logged-in">
        <Loader />
      </Div100vh>
    );
  }

  if (!user) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName={brandName}
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/confirm/:token" element={<Confirm />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/logout" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </ThemeProvider>
    );
  }

  let _routes = routes;
  if (isAdmin) {
    _routes = routes.concat(adminRoutes);
  } else if (isGuest) {
    _routes = guestRoutes;
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={brandName}
            routes={_routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(isAdmin ? routes.concat(adminRoutes) : routes)}
        {isAdmin && (
          <>
            <Route path="admin/users/:userId" element={<User />} />
            <Route path="admin/projects/:projectId" element={<Project />} />
            <Route path="admin/categories/:categoryId" element={<Categories singleCat />} />
            <Route path="admin/cad/:cadSystemId" element={<CadSystem />} />
            <Route path="admin/newpart/:projectId" element={<AdminNewPart />} />
          </>
        )}
        <Route path="app/new-dataset/:projectId" element={<NewPart />} />
        <Route path="app/new-dataset" element={<NewPart />} />
        <Route path="app/dataset/:partId" element={<OpenPart />} />
        <Route path="/logout" element={<Login signOff />} />
        <Route path="*" element={<Navigate to="/app/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
