// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useQueryClient } from "react-query";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import { useNavigate } from "react-router-dom";

// Data
import { useGetAdminUsers, useAdminInviteUser } from "hooks/useQueries";
import { Grid, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import { useState } from "react";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import { toast } from "react-toastify";

function Users() {
  const { data: users } = useGetAdminUsers();
  const { mutateAsync: inviteUser } = useAdminInviteUser();
  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const userDataTable = {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "email", accessor: "email" },
      { Header: "status", accessor: "status" },
      { Header: "2fa enabled", accessor: "mfa_enabled" },
      { Header: "roles", accessor: "roles" },
    ],

    rows: users
      ? users.map((user) => ({
          id: user.id,
          email: user.email,
          status: user.active ? "active" : "in-active",
          mfa_enabled: user.mfa_enabled ? "✅" : "❗️",
          roles: user.roles?.join(", "),
        }))
      : [],
  };

  const inviteHandler = async (e) => {
    const mailFormat =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

    setError("");
    e.preventDefault();
    if (users?.find((user) => user.email === email)) {
      setError("This user already exists");
    } else if (!email.toLowerCase().match(mailFormat)) {
      setError("Enter a valid email address");
    } else {
      try {
        await inviteUser({
          email,
        });
        setEmail("");
        toast.success("New user invited!");
        queryCache.invalidateQueries("getAdminUsers");
      } catch (err) {
        if (err.response?.data?.message) {
          toast.warning(err.response?.data?.message);
          setError(err.response?.data?.message);
        } else {
          toast.warning("Error, something went wrong");
        }
      }
    }
  };

  const userClick = (id) => {
    navigate(`/admin/users/${id}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  person
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Invite a new user
              </MDTypography>
            </MDBox>
            <form onSubmit={inviteHandler}>
              <MDBox p={2} style={{ display: "flex", alignItems: "center" }}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <MDButton
                  variant="gradient"
                  type="submit"
                  color="info"
                  onClick={inviteHandler}
                  style={{ minWidth: 130, marginLeft: 20 }}
                >
                  Send invite
                </MDButton>
              </MDBox>
              {error && (
                <MDBox p={2}>
                  <MDAlert color="error">{error}</MDAlert>
                </MDBox>
              )}
            </form>
          </Card>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Registered users
            </MDTypography>
            <MDTypography variant="button" color="text">
              Sort by clicking the columns, filter by using the search field
            </MDTypography>
          </MDBox>
          <DataTable table={userDataTable} canSearch onClick={userClick} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Users;
