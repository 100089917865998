// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import _ from "lodash";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import "./Dashboard.scss";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";

// Data
import {
  useGetParts,
  useGetProjects,
  useGetAdminUsers,
  useGetAdminSingleUser,
} from "hooks/useQueries";
import { Grid, Icon, Tooltip } from "@mui/material";
import { useState } from "react";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useAuth } from "../../../context/AuthProvider";

function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: parts } = useGetParts();
  const [sortAttribute, setSortAttribute] = useState("partNumber");
  const [sortDirection, setSortDirection] = useState("asce");
  const [search, setSearch] = useState("");
  const { data: selectedUser } = useGetAdminSingleUser({ id: user.sub });
  const isGuest = user?.roles?.includes("guest");

  const changeSortHandler = (newAttribute) => {
    if (newAttribute === sortAttribute) {
      setSortDirection(sortDirection === "desc" ? "asce" : "desc");
    } else {
      setSortAttribute(newAttribute);
      setSortDirection("desc");
    }
  };

  const dataSet = parts
    ? parts.map((part) => ({
        id: part.id,
        partNumber: part.part_number,
        name: part.name,
        status:
          !part.reviewers && !part.stage
            ? "draft"
            : `${
                part.reviewers.filter((review) => review.status === "approved").length >= 3
                  ? "approved"
                  : part.stage
              }`,
        keywords: part.tags.join(", "),
      }))
    : [];

  let sortedDataSet = _.orderBy(dataSet, sortAttribute, [sortDirection]);

  if (search) {
    sortedDataSet = sortedDataSet.filter(
      (part) =>
        part.partNumber.toLowerCase().includes(search.toLowerCase()) ||
        part.name.toLowerCase().includes(search.toLowerCase()) ||
        part.status.toLowerCase().includes(search.toLowerCase()) ||
        part.keywords.toLowerCase().includes(search.toLowerCase())
    );
  }

  const partClick = (id) => {
    navigate(`/app/dataset/${id}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!selectedUser || selectedUser.mfa_enabled ? (
        ""
      ) : (
        <>
          <MDButton
            variant="gradient"
            color="success"
            onClick={() => navigate(`/profile`)}
            style={{ minWidth: 130 }}
          >
            Enable Two Factor Authentication
          </MDButton>
          <br />
          <br />
        </>
      )}
      {isGuest ? (
        ""
      ) : (
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => navigate(`/app/new-dataset`)}
          style={{ minWidth: 130 }}
        >
          New part
        </MDButton>
      )}
      {isGuest ? (
        ""
      ) : (
        <MDBox py={3}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <PartsBlock
                title="Release level 1"
                dataSet={parts?.filter((p) => p.stage === "stage_1")}
              />
              <PartsBlock
                title="Release level 2"
                dataSet={parts?.filter((p) => p.stage === "stage_2")}
              />
              <PartsBlock
                title="Release level 3"
                dataSet={parts?.filter((p) => p.stage === "stage_3")}
              />
            </Grid>
          </MDBox>
        </MDBox>
      )}
      <MDBox mb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Explore database
            </MDTypography>
            <MDTypography variant="button" color="text">
              Sort by clicking the columns, filter by using the search field
            </MDTypography>

            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(currentTarget.value);
                }}
              />
            </MDBox>
          </MDBox>
          <Table>
            <MDBox component="thead">
              <TableRow>
                <DataTableHeadCell
                  sorted={sortAttribute === "part_number" ? sortDirection : "asce"}
                  onClick={() => changeSortHandler("partNumber")}
                >
                  Part number
                </DataTableHeadCell>
                <DataTableHeadCell
                  sorted={sortAttribute === "name" ? sortDirection : "none"}
                  onClick={() => changeSortHandler("name")}
                >
                  Name
                </DataTableHeadCell>
                <DataTableHeadCell
                  sorted={sortAttribute === "status" ? sortDirection : "none"}
                  onClick={() => changeSortHandler("status")}
                >
                  Status
                </DataTableHeadCell>
                <DataTableHeadCell
                  sorted={sortAttribute === "keywords" ? sortDirection : "none"}
                  onClick={() => changeSortHandler("keywords")}
                >
                  Keywords
                </DataTableHeadCell>
              </TableRow>
            </MDBox>
            <TableBody>
              {sortedDataSet?.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  style={{ cursor: "pointer" }}
                  onClick={() => partClick(row.id)}
                >
                  <DataTableBodyCell>{row.partNumber}</DataTableBodyCell>
                  <DataTableBodyCell>{row.name}</DataTableBodyCell>
                  <DataTableBodyCell>{row.status}</DataTableBodyCell>
                  <DataTableBodyCell>{row.keywords}</DataTableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

function PartsBlock({ title, dataSet }) {
  const navigate = useNavigate();
  const [sortAttribute, setSortAttribute] = useState("part_number");
  const [sortDirection, setSortDirection] = useState("asce");
  const { data: projects } = useGetProjects();
  const { data: users } = useGetAdminUsers();

  const changeSortHandler = (newAttribute) => {
    if (newAttribute === sortAttribute) {
      setSortDirection(sortDirection === "desc" ? "asce" : "desc");
    } else {
      setSortAttribute(newAttribute);
      setSortDirection("desc");
    }
  };

  const sortedDataSet = _.orderBy(dataSet, sortAttribute, [sortDirection]);

  return (
    <Grid item xs={12} sm={12} lg={4}>
      <Card sx={{ height: "600px", overflowX: "scroll", overflowY: "scroll" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6">{title}</MDTypography>
        </MDBox>
        <Table>
          <MDBox component="thead">
            <TableRow>
              <DataTableHeadCell
                sorted={sortAttribute === "part_number" ? sortDirection : "asce"}
                onClick={() => changeSortHandler("part_number")}
              >
                part number
              </DataTableHeadCell>
              <DataTableHeadCell
                sorted={sortAttribute === "name" ? sortDirection : "none"}
                onClick={() => changeSortHandler("name")}
              >
                Name
              </DataTableHeadCell>
              <DataTableHeadCell
                sorted={sortAttribute === "project_id" ? sortDirection : "none"}
                onClick={() => changeSortHandler("project_id")}
              >
                Product
              </DataTableHeadCell>
              <DataTableHeadCell sorted={false}> </DataTableHeadCell>
            </TableRow>
          </MDBox>
          <TableBody>
            {sortedDataSet?.map((row) => (
              <TableRow
                key={row.id}
                hover
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/app/dataset/${row.id}`)}
              >
                <DataTableBodyCell>{row.part_number}</DataTableBodyCell>
                <DataTableBodyCell>{row.name}</DataTableBodyCell>
                <DataTableBodyCell>
                  {projects?.find((project) => project.id === row.project_id)?.name}
                </DataTableBodyCell>
                <DataTableBodyCell>
                  {row.locked_by ? (
                    <Tooltip
                      title={`Locked by: ${users?.find((u) => u.id === row.locked_by)?.email}`}
                      placement="top"
                    >
                      <Icon>lock</Icon>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </DataTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </Grid>
  );
}

export default Dashboard;
