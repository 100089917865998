// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useQueryClient } from "react-query";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import { Link, useNavigate, useParams } from "react-router-dom";

// Data
import {
  useGetCategories,
  useAddCategory,
  useGetSingleCategory,
  useUpdateCategory,
  useDeleteCategory,
} from "hooks/useQueries";
import { Grid } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Div100vh from "react-div-100vh";
import Loader from "components/Loader/Loader";
import { confirmAlert } from "react-confirm-alert";

Modal.setAppElement("#root");

function Categories({ singleCat }) {
  const params = useParams();
  const { categoryId } = params;

  const { data: selectedCategory, isLoading } = useGetSingleCategory({
    id: categoryId,
    options: { enabled: !!(singleCat && categoryId) },
  });
  const { data: categories } = useGetCategories();
  const { mutateAsync: addCategory } = useAddCategory();
  const { mutateAsync: updateCategory } = useUpdateCategory({ id: categoryId });
  const { mutateAsync: deleteCategory } = useDeleteCategory({ id: categoryId });

  const [editMode, setEditMode] = useState(false);

  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [categoryNumber, setCategoryNumber] = useState();
  const [saveName, setSaveName] = useState("");
  const [saveCategoryNumber, setSaveCategoryNumber] = useState();
  const [valuesUpdated, setValueUpdated] = useState(false);
  const [previousCategory, setPreviousCategory] = useState();

  useEffect(() => {
    if (
      (!valuesUpdated && selectedCategory) ||
      (selectedCategory && previousCategory && previousCategory !== selectedCategory)
    ) {
      setValueUpdated(true);
      setSaveName(selectedCategory.name);
      setSaveCategoryNumber(selectedCategory.number);
      setPreviousCategory(selectedCategory);
    }
  }, [valuesUpdated, selectedCategory, previousCategory]);

  const parentCategory =
    categories &&
    selectedCategory?.parent_category_id &&
    categories.find((cat) => cat.id === selectedCategory.parent_category_id);

  const parentParentCategory =
    categories &&
    parentCategory?.parent_category_id &&
    categories.find((cat) => cat.id === parentCategory.parent_category_id);

  let layer = 1;
  if (parentCategory?.parent_category_id) {
    layer = 3;
  } else if (parentCategory) {
    layer = 2;
  }

  const categoryDataTable = {
    columns: [
      { Header: "Id", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Number", accessor: "number" },
    ],

    rows: categories
      ? categories
          .filter((cat) =>
            singleCat ? cat.parent_category_id === categoryId : !cat.parent_category_id
          )
          .map((category) => ({
            id: category.id,
            name: category.name,
            number: category.number,
          }))
      : [],
  };

  const deleteHandler = () => {
    confirmAlert({
      title: "Are you sure?",
      message: "Really delete this category?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deleteCategory();
            toast.success("Category deleted!");
            queryCache.invalidateQueries("getCategories");
            navigate("/admin/categories");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const categoryClick = (id) => {
    navigate(`/admin/categories/${id}`);
  };

  const submitCategoryHandler = async (e) => {
    e.preventDefault();
    if (!name || !categoryNumber) {
      toast.warning("Please enter all required fields");
      return;
    }
    try {
      await addCategory(
        singleCat
          ? {
              name,
              number: Number(categoryNumber),
              parent_category_id: categoryId,
            }
          : {
              name,
              number: Number(categoryNumber),
            }
      );
      setName("");
      setCategoryNumber("");
      setModalIsOpen(false);
      toast.success("Category added!");
      queryCache.invalidateQueries("getCategories");
    } catch (err) {
      if (err.response?.data?.message) {
        toast.warning(err.response?.data?.message);
      } else {
        toast.warning("Error, something went wrong");
      }
    }
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!saveName || !saveCategoryNumber) {
      toast.warning("Please enter all required fields");
      return;
    }
    try {
      await updateCategory({
        ...selectedCategory,
        name: saveName,
        number: Number(saveCategoryNumber),
      });
      setEditMode(false);
      toast.success("Changes saved!");
      queryCache.invalidateQueries("getCategories");
      queryCache.invalidateQueries(`getSingleCategory-${categoryId}`);
    } catch (err) {
      if (err.response?.data?.message) {
        toast.warning(err.response?.data?.message);
      } else {
        toast.warning("Error, something went wrong");
      }
    }
  };

  if (isLoading) {
    return (
      <Div100vh className="not-logged-in">
        <Loader />
      </Div100vh>
    );
  }

  if (singleCat && !selectedCategory) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Category not found
              </MDTypography>
              <MDTypography variant="button" color="text">
                Cannot find category with id: {categoryId}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="New Category"
        style={{
          overlay: {
            backgroundColor: "#00000085",
            zIndex: 10000,
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 500,
          },
        }}
      >
        <MDBox p={3} sm={12}>
          <MDTypography variant="h5">
            {singleCat ? `Add new level ${layer + 1} category` : "New category"}
          </MDTypography>
        </MDBox>
        <MDBox component="form" onSubmit={submitCategoryHandler}>
          <Grid item xs={3} sm={12}>
            <MDInput
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <div style={{ height: 10 }} />
          <Grid item xs={12} sm={12}>
            <MDInput
              fullWidth
              label="Internal category number"
              value={categoryNumber}
              onChange={(e) => setCategoryNumber(e.target.value)}
            />
          </Grid>
          <div style={{ height: 10 }} />
          <Grid item xs={12} sm={12}>
            <MDButton variant="gradient" type="submit" color="info" style={{ minWidth: 130 }}>
              Save
            </MDButton>
          </Grid>
        </MDBox>
      </Modal>
      <DashboardLayout>
        <DashboardNavbar customCrumb={singleCat ? selectedCategory.name : null} />
        {singleCat && (
          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {`${selectedCategory.name}`}
                </MDTypography>
                <MDTypography variant="button" color="text">
                  {selectedCategory.id}
                </MDTypography>
              </MDBox>
              <Card sx={{ boxShadow: "none" }}>
                <MDBox p={2}>
                  <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                    Category details
                  </MDTypography>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <strong>Name:</strong>
                          </p>
                        </td>
                        <td>
                          <p>{selectedCategory.name}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <strong>Internal category number:</strong>
                          </p>
                        </td>
                        <td>
                          <p>{selectedCategory.number}</p>
                        </td>
                      </tr>
                      {parentParentCategory && (
                        <tr>
                          <td>
                            <p>
                              <strong>Top level Parent Category:</strong>
                            </p>
                          </td>
                          <td>
                            <p>
                              <Link to={`/admin/categories/${parentParentCategory.id}`}>
                                {parentParentCategory.name}
                              </Link>
                            </p>
                          </td>
                        </tr>
                      )}
                      {parentCategory && (
                        <tr>
                          <td>
                            <p>
                              <strong>Parent Category:</strong>
                            </p>
                          </td>
                          <td>
                            <p>
                              <Link to={`/admin/categories/${parentCategory.id}`}>
                                {parentCategory.name}
                              </Link>
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div style={{ height: 20 }} />
                  {editMode ? (
                    <>
                      <MDBox component="form" onSubmit={saveHandler}>
                        <Grid item xs={3} sm={12}>
                          <MDInput
                            fullWidth
                            label="Name"
                            value={saveName}
                            onChange={(e) => setSaveName(e.target.value)}
                          />
                        </Grid>
                        <div style={{ height: 10 }} />
                        <Grid item xs={12} sm={12}>
                          <MDInput
                            fullWidth
                            type="number"
                            label="Internal category number"
                            value={saveCategoryNumber}
                            onChange={(e) => setSaveCategoryNumber(e.target.value)}
                          />
                        </Grid>
                        <div style={{ height: 10 }} />
                        <Grid item xs={12} sm={12}>
                          <MDButton
                            variant="gradient"
                            type="submit"
                            color="success"
                            style={{ minWidth: 130 }}
                          >
                            Save
                          </MDButton>{" "}
                          <MDButton
                            variant="gradient"
                            color="error"
                            style={{ minWidth: 130, marginLeft: 20 }}
                            onClick={() => setEditMode(false)}
                          >
                            Cancel edit
                          </MDButton>
                        </Grid>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDButton
                        variant="gradient"
                        color="info"
                        style={{ minWidth: 130 }}
                        onClick={() => setEditMode(true)}
                      >
                        Edit
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="error"
                        style={{ minWidth: 130, marginLeft: 20 }}
                        onClick={deleteHandler}
                      >
                        Delete
                      </MDButton>
                    </>
                  )}
                </MDBox>
              </Card>
            </Card>
          </MDBox>
        )}
        {layer < 3 && (
          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {singleCat ? `Level ${layer + 1} categories` : "Categories"}
                    </MDTypography>
                    <MDTypography variant="button" color="text">
                      Sort by clicking the columns, filter by using the search field
                    </MDTypography>
                  </div>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setModalIsOpen(true)}
                    style={{ minWidth: 130, marginLeft: 20 }}
                  >
                    {singleCat ? "Add subcategory" : "New category"}
                  </MDButton>
                </div>
              </MDBox>
              <DataTable table={categoryDataTable} canSearch onClick={categoryClick} />
            </Card>
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
}

export default Categories;
