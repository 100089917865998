/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import Login from "layouts/authentication/sign-in";
import Users from "layouts/admin/users/users";
import Projects from "layouts/admin/projects/projects";
import Categories from "layouts/admin/categories/categories";
import CadSystems from "layouts/admin/cadSystems/cadSystems";
import Dashboard from "layouts/regular/dashboard/Dashboard";
import NewPart from "layouts/regular/part/NewPart";
import Documentation from "layouts/regular/Documentation";
import MyAccount from "layouts/regular/MyAccount";

export const adminRoutes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Users",
        key: "users",
        route: "/admin/users",
        component: <Users />,
      },
      {
        name: "Products",
        key: "projects",
        route: "/admin/projects",
        component: <Projects />,
      },
      {
        name: "Categories",
        key: "categories",
        route: "/admin/categories",
        component: <Categories />,
      },
      {
        name: "CAD systems",
        key: "cad",
        route: "/admin/cad",
        component: <CadSystems />,
      },
    ],
  },
];

export const guestRoutes = [
  {
    type: "collapse",
    name: "My account",
    key: "my-account",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        route: "/profile",
        component: <MyAccount />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        component: <Login signOff />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "App",
    key: "app",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/app/dashboard",
        component: <Dashboard />,
      },
    ],
  },
];

const routes = [
  {
    type: "collapse",
    name: "My account",
    key: "my-account",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        route: "/profile",
        component: <MyAccount />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        component: <Login signOff />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Documentation",
    key: "documentation-group",
    icon: <Icon fontSize="medium">book</Icon>,
    collapse: [
      {
        name: "App Documentation",
        key: "documentation",
        route: "/documentation",
        component: <Documentation />,
      },
    ],
  },
  {
    type: "collapse",
    name: "App",
    key: "app",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/app/dashboard",
        component: <Dashboard />,
      },
      {
        name: "New Dataset",
        key: "new-dataset",
        route: "/app/new-dataset",
        component: <NewPart />,
      },
    ],
  },
];

export default routes;
