// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useQueryClient } from "react-query";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { Switch } from "@mui/material";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import {
  useGetAdminSingleUser,
  useGetAdminRoles,
  useAdminUpdateUser,
  useAdminDeleteUser,
} from "hooks/useQueries";
import { useParams, useNavigate } from "react-router-dom";
import Div100vh from "react-div-100vh";
import Loader from "components/Loader/Loader";
import { useAuth } from "context/AuthProvider";
import { confirmAlert } from "react-confirm-alert";

function User() {
  const params = useParams();
  const { userId } = params;
  const navigate = useNavigate();

  const { data: selectedUser, isLoading } = useGetAdminSingleUser({ id: userId });
  const { data: roles } = useGetAdminRoles();
  const { mutateAsync: updateUser } = useAdminUpdateUser({ id: userId });
  const { mutateAsync: deleteUser } = useAdminDeleteUser({ id: userId });
  const { user } = useAuth();
  const queryCache = useQueryClient();

  const updateUserRole = async (role) => {
    const newRoles = selectedUser.roles ? [...selectedUser.roles] : [];
    if (newRoles.includes(role)) {
      newRoles.splice(newRoles.indexOf(role), 1);
    } else {
      newRoles.push(role);
    }

    if (user.sub === userId && !newRoles.includes("admin")) {
      toast.warning("You cannot remove your own admin rights");
      return;
    }

    await updateUser({
      ...selectedUser,
      roles: newRoles,
    });
    queryCache.invalidateQueries(`getAdminSingleUser-${userId}`);
  };

  const updateActive = async () => {
    if (user.sub === userId) {
      toast.warning("You cannot change the active status of your own account");
      return;
    }
    /*
    await resetUser({
      id: userId,
      email: selectedUser.email,
    });
    */
    queryCache.invalidateQueries(`getAdminSingleUser-${userId}`);
  };

  const delUser = () => {
    if (user.sub === userId) {
      toast.warning("You cannot delete your own account");
      return;
    }
    confirmAlert({
      title: "Are you sure?",
      message: "Really delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deleteUser();
            toast.success("User deleted!");
            queryCache.invalidateQueries("getAdminUsers");
            navigate("/admin/users");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  if (isLoading) {
    return (
      <Div100vh className="not-logged-in">
        <Loader />
      </Div100vh>
    );
  }

  if (!selectedUser) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                User not found
              </MDTypography>
              <MDTypography variant="button" color="text">
                Cannot find user with id: {userId}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar customCrumb={selectedUser.email} />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {`${selectedUser.email}`}
            </MDTypography>
            <MDTypography variant="button" color="text">
              {selectedUser.id}
            </MDTypography>
          </MDBox>
          <Card sx={{ boxShadow: "none" }}>
            <MDBox p={2}>
              <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                User details
              </MDTypography>
              <br />
              <table>
                <tr>
                  <td>
                    <p>
                      <strong>Email:</strong>
                    </p>
                  </td>
                  <td>
                    <p>{selectedUser.email}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>2FA enabled:</strong>
                    </p>
                  </td>
                  <td>
                    <p>{selectedUser.mfa_enabled ? "✅" : "❗️"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>Active:</strong>
                    </p>
                  </td>
                  <td>
                    <Switch checked={selectedUser?.active} onChange={updateActive} />
                  </td>
                </tr>
              </table>
            </MDBox>

            <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
              >
                Roles
              </MDTypography>
              {roles.map((role) => (
                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5} key={role.id}>
                  <MDBox mt={0.5}>
                    <Switch
                      checked={selectedUser?.roles?.includes(role.id)}
                      onChange={() => updateUserRole(role.id)}
                    />
                  </MDBox>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {role.id}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
            <Card sx={{ boxShadow: "none" }}>
              <MDBox p={2}>
                <MDButton
                  variant="gradient"
                  type="submit"
                  color="error"
                  onClick={delUser}
                  style={{ width: "fit-content" }}
                >
                  Delete
                </MDButton>
              </MDBox>
            </Card>
          </Card>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default User;
