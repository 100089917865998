import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import { toast } from "react-toastify";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Div100vh from "react-div-100vh";

import { useQueryClient } from "react-query";
import { useEnableMFA, useConfirmMFA, useGetAdminSingleUser } from "../../hooks/useQueries";
import Loader from "../../components/Loader/Loader";
import { useAuth } from "../../context/AuthProvider";

function Profile() {
  const { user } = useAuth();
  const queryCache = useQueryClient();
  const { data: selectedUser, isLoading } = useGetAdminSingleUser({ id: user.sub });

  const { mutateAsync: enable } = useEnableMFA({ id: user.sub });
  const { mutateAsync: confirm } = useConfirmMFA({ id: user.sub });

  const [code, setCode] = useState("");

  const enableMFA = async () => {
    await enable({});
    toast.info("Please check your email for the security code");
  };

  const activateMFA = async () => {
    await confirm({ otp: code });
    queryCache.invalidateQueries(`getAdminSingleUser-${user.sub}`);
  };

  if (isLoading) {
    return (
      <Div100vh className="not-logged-in">
        <Loader />
      </Div100vh>
    );
  }

  if (!selectedUser) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                User not found
              </MDTypography>
              <MDTypography variant="button" color="text">
                Cannot find user with id: {user.sub}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  }

  if (selectedUser.mfa_enabled) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} lineHeight={2}>
              <h1>Profile</h1>
              <br />
              <strong>2FA enabled</strong>
              <p>✅</p>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={2}>
            <h1>Profile</h1>
            <br />
            <strong>2FA enabled</strong>
            <p>❗</p>
            <MDButton
              variant="gradient"
              type="submit"
              color="info"
              onClick={() => enableMFA(user.sub)}
            >
              Enable 2FA
            </MDButton>
            <br />
            <MDTypography variant="button" color="text">
              After clicking the Enable 2FA button, check your email and enter the security code
              below
            </MDTypography>
            <br />
            <MDInput
              style={{ zIndex: 0, marginRight: 5 }}
              label="Security code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <MDButton
              variant="gradient"
              type="submit"
              color="success"
              onClick={() => activateMFA(user.sub, code)}
            >
              Activate
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Profile;
