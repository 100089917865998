// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useQueryClient } from "react-query";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import { useGetSingleCadSystem, useUpdateCadSystem, useDeleteCadSystem } from "hooks/useQueries";
import { useParams, useNavigate } from "react-router-dom";
import Div100vh from "react-div-100vh";
import Loader from "components/Loader/Loader";
import { confirmAlert } from "react-confirm-alert";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";

function CadSystem() {
  const params = useParams();
  const { cadSystemId } = params;
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const { data: selectedCadSystem, isLoading } = useGetSingleCadSystem({ id: cadSystemId });
  const { mutateAsync: updateCadSystem } = useUpdateCadSystem({ id: cadSystemId });
  const { mutateAsync: deleteCadSystem } = useDeleteCadSystem({ id: cadSystemId });

  const queryCache = useQueryClient();
  const [valuesUpdated, setValueUpdated] = useState(false);

  useEffect(() => {
    if (!valuesUpdated && selectedCadSystem) {
      setValueUpdated(true);
      setName(selectedCadSystem.name);
    }
  }, [valuesUpdated, selectedCadSystem]);

  const deleteHandler = () => {
    confirmAlert({
      title: "Are you sure?",
      message: "Really delete this CAD System?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await deleteCadSystem();
            toast.success("CAD System deleted!");
            queryCache.invalidateQueries("getCadSystems");
            navigate("/admin/cad");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!name) {
      toast.warning("Please enter all required fields");
      return;
    }
    try {
      await updateCadSystem({ ...selectedCadSystem, name });
      toast.success("Changes saved!");
      queryCache.invalidateQueries("getCadSystems");
      queryCache.invalidateQueries(`getSingleCadSystem-${cadSystemId}`);
    } catch (err) {
      if (err.response?.data?.message) {
        toast.warning(err.response?.data?.message);
      } else {
        toast.warning("Error, something went wrong");
      }
    }
  };

  if (isLoading) {
    return (
      <Div100vh className="not-logged-in">
        <Loader />
      </Div100vh>
    );
  }

  if (!selectedCadSystem) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                CAD System not found
              </MDTypography>
              <MDTypography variant="button" color="text">
                Cannot find CAD System with id: {cadSystemId}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {`${selectedCadSystem.name}`}
            </MDTypography>
            <MDTypography variant="button" color="text">
              {selectedCadSystem.id}
            </MDTypography>
          </MDBox>
          <Card sx={{ boxShadow: "none" }}>
            <MDBox p={2}>
              <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                CAD System details
              </MDTypography>
              <div style={{ height: 30 }} />
              <MDBox component="form" onSubmit={saveHandler}>
                <Grid item xs={3} sm={12}>
                  <MDInput
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <div style={{ height: 10 }} />
                <Grid item xs={12} sm={12}>
                  <MDButton
                    variant="gradient"
                    type="submit"
                    color="success"
                    style={{ minWidth: 130 }}
                  >
                    Save
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="error"
                    style={{ minWidth: 130, marginLeft: 20 }}
                    onClick={deleteHandler}
                  >
                    Delete
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default CadSystem;
